import { useState } from "react";

export default function Footer() {
	return (
		<div id="footer" className="flex-row footer-row">
			<a href="https://thewaycenter.net/terms-and-conditions
" className="footer-link poppins px-13" target="_blank" rel="noreferrer">
				Terms
			</a>
			<a href="https://thewaycenter.net/terms-and-conditions
" className="footer-link poppins px-13" target="_blank" rel="noreferrer">
				Privacy Policy
			</a>
			{/* <a href="#footer" className="footer-link poppins px-13">
        Documentation
      </a> */}
		</div>
	);
}
